.simulacao-do-anuncio {
    background-color: #ffcc29;
    padding-bottom: 5px;
    padding-top: 1px;
}

.formulario-de-cadastro-titulo {
    background-color: #4f4f4f;
    padding: 15px;
    text-align: center;


    & h2 {
        color: #fff !important;
    }


}

.interna h1,
.interna h2 {
    color: black;
    font-weight: bold;
    font-size: 35px;
}

.anuncio {
    background-color: #ffcc29;
    margin-top: 15px;
    padding-bottom: 10px;
    overflow: hidden;
}

.form-group {
    margin-bottom: 15px;
}

.tipo-de-anuncio {
    padding-top: 15px;
    font-weight: bold;
    color: #4f4f4f;
    text-align: center;
    font-size: 16px;
}

.anuncio-options {
    padding-top: 9px;
    padding-left: 3px;
    color: #4f4f4f;
}

.codigo-promocional {
    background-color: #edecec;
    padding: 15px;
   
}

.posicao-preview {
    position: sticky;
    top: 75px;
}

h2 {
    font-size: 27px;
}

.assinatura {
    background-color: #ffcc29;
    padding-top: 1px;
    padding-bottom: 10px;
    margin-top: 15px !important;
    text-align: center;
}

.tipo-de-anuncio {
    padding-top: 15px;
    font-weight: bold;
    color: #4f4f4f;
    text-align: center;
    font-size: 16px;
}

.anuncio-options {
    padding-top: 9px;
    padding-left: 3px;
    color: #4f4f4f;
}

.forma-de-pagamento i {
    padding-top: 8px;
    padding-left: 11px;
}

.interna i {
    font-size: 25px;
}

.margin-top-20 {
    margin-top: 20px !important;

}

a {
    color: #4f4f4f;
    outline: 0;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.list-inline {
    & li {
        padding-right: 5px;
        padding-left: 1px;
    }
}

.simulacao .cartao {
    background-color: #fff;
    border: 1px solid #ddd;
    width: 100%;
}

.caderno .cartao {
    background-color: #edecec;
    margin-bottom: 20px;
}

.assinatura .preco {
    font-size: 40px;
}

.formulario-de-cadastro {
    background-color: #4f4f4f;
    text-align: center;
    padding: 22px 10px;
    color: #fff !important;
    font-size: 27px;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%
}

.form-control {
    opacity: 0.8;
    height: 50px;
    padding-left: 40px;
    font-weight: bold;
   /*  font-size: 20px; */
}

.interna i {
    font-size: 25px;
}

/* .icone-form {
    position: absolute;
    margin-top: 18px;
    z-index: 1;
    padding-left: 14px;
    color: #ffcc29 !important;
} */

.botao-procurar {
    display: flex;
    justify-content: center;
    align-items: center;

    &button {
        width: 100%
    }
}

.form-group div {
    margin-top: 5px;
}

/* .webcard {
    border: 1px solid red
} */

.choose-main #btnDeleteImagem {
    margin-top: 5px;
    color: rgba(255, 0, 0, 0.61);
}

.comImagem {
     border: 5px solid black;
   margin-bottom: 15px;
}
.comImagem img {
    width: 100%;
    height: 330px;
   
}

.codigo-promocional h4 {
    font-size: 17px;
    font-weight: 500;
}
.codigo-promocional h5 {
    font-size: 13px;
    font-weight: 500;
}

#btnDescImagem {
    font-size: 22px;
    padding: 5px;
}