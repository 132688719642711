.adesivo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.adesivo-container>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.adesivo-container>div>.qr-code>canvas {
  position: absolute;
  left: 74px;
  top: 133px;
}

.adesivo-container button {
  background: #FF730F;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.5, rgb(255, 115, 15)), color-stop(0.84, rgb(255, 132, 0)));
  font-size: 30px;
  font-family: 'myriad_pro_lightbold';
  border-radius: 6px;
  padding: 10px;
  text-align: center;
  display: block;
  color: #fff;
  text-decoration: none;
  margin: 20px 10px 0 auto;
  display: inline;
}

@media print {
  .no-print {
    display: none;
  }

  .adesivo-container>div>.qr-code>canvas {
    position: absolute;
    left: 60px;
    top: 60px;
    height: 145px !important;
    width: 145px !important;
  }

  .qr-code>img{
    width: 500px;
  }
}