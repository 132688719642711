.users {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

}

.custom-button {
    color: #000;
    border: 1px solid #5555;
    cursor: pointer;
    font-size: 13px;
    box-shadow: inset 0px -13px 8px rgba(0, 0, 0, 0.1);
    /* Adiciona sombra na parte inferior */
}

/* Estilizando o botão quando hover */
.custom-button:hover {
    /* box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); */
    /* Aumenta a sombra no hover */
}

#buscar {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #5555;
    font-size: 13px;
    height: 30px;
    width: 295px;
}

#buscar:focus {
    border: 1px solid #75b9f0;
}

/* #btnBuscar {
    border: 1px solid #5555;
    border-radius: 2px;
    margin: 0 0 0 5px;
} */

.icon-user {
    background-position: -167px 0 !important;
}

.icon-search {
    background-position: -48px 0 !important;
}


[class^="icon-"],
[class*=" icon-"] {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: .3em;
    line-height: 14px;
    vertical-align: text-top;
    background-image: url(../img/icon/glyphicons-halflings.png);
    background-position: 14px 14px;
    background-repeat: no-repeat;
}

.users,
.Cadernos,
.InfoCadernos,
.Atividades {
    font-size: 13px
}

.selecionada {
    --bs-table-bg: #FCF8BA !important
        /* Mudar para a cor desejada */
}

tr {
    cursor: pointer
}

.codigoId {
    font-size: 1.5rem;
    background-color: gainsboro;
    border-radius: 3px;
    padding: 0px 5px 0px 5px;
}

.users .table-perfil {
    overflow: auto;
}

.users table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.users table th {
   /*  border: 1px solid #000000; */
    /* text-align: left; */
    padding: 8px;
    width: 100px !important;
    background-color: #ffff00;
}

.users table th:nth-child(6) {
   /*  background-color: #ff0000;*/
} 

.users table th:nth-child(8) {
    width: 50px !important;
}

.users table th:nth-child(9) {
    width: 80px !important;
}

.users table th:nth-child(10) {
    width: 80px !important;
}

.users table th:nth-child(11) {
    width: 113px !important;
}

.users table th:nth-child(19) {
    width: 200px !important;
}

.users table th:nth-child(20) {
    width: 200px !important;
}

.app-users .SearchOption {
    display: flex;
    gap: 5px;
    padding: 5px 0;
}

.app-users .SearchOption label {
    display: flex;
    gap: 1px;
}