.metadados-icons i img {
    vertical-align: baseline;
}

.webcard > p {
    text-align: left;
    margin: 0;
    padding-top: 10px;
}

