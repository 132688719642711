.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-left: 5px;
    font-weight: bold;
    color: #007bff;
    font-size: 14px;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Ajuste a posição conforme necessário */
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  
.help-span {
    border: 1px solid gray;
    border-radius: 100%;
    padding: 0px 2px 0px 2px;
    background-color: gray;
    color: #fff;
    margin-left: 4px;
}