.select-pfpj i {
    display: block;
    font-size: 26px
}

#bg-registro-usuario input,
#bg-registro-usuario select {
    margin-top: 10px;
}

.subtitulo {
    text-align: center;
    color: #000000;
    font-weight: 700;
    padding-bottom: 30px;
}