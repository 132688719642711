.dropdown>ul>li>button {
    border: none;
    background-color: #ffffff;
    width: 100%;
    height: 30px;
    text-align: left;
}

.dropdown>ul>li>button:hover {
    background-color: gray;
    color: #ffffff;
}