.letter {
    /* border: 1px solid red; */
    padding: 20px 0px;
}

.letter > .letter-div {
    padding: 20px;
    background-color: #4F4F4F;
    overflow: hidden;
    white-space: nowrap;
}

.letter > .letter-div > span {
   /*  border: 1px solid orangered; */
    width: 100%;
    color: #fff;
    font-size: 1.5rem;
    padding-right: 100%;
}

.letter > .letter-div > .div-marquee > span {
   /*  border: 1px solid orangered; */
    width: 100%;
    color: #fff;
    font-size: 1.5rem;
    padding-right: 100%;
}

.marquee {
    display: flex;
    /* padding-left: 100%; */ /* Começa fora da div */
    animation: scrolling 20s linear infinite; /* Duração da animação e repetição infinita */
}

@keyframes scrolling {
    from {
        transform: translateX(100%); /* Ponto inicial (direita fora da div) */
    }
    to {
        transform: translateX(-100%); /* Ponto final (esquerda fora da div) */
    }
}

@media (max-width: 768px) {
    .letter > .letter-div > .div-marquee > span {
         font-size: 18px;
     }
}