.msg-body-1 {
    border: 1px solid red;
    background-color: #4f4f4f;
    color: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* max-height: 1406px; */
  /*   position: absolute;
    bottom: 0; */
}

.msg-body-2 {
    border: 1px solid red;
    background-color: #4f4f4f;
    color: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* max-height: 1406px; */

}

