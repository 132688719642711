.ContactForm {
    color: #5f6f7e;
}

#contact-logo {
    width: 100px;
}

.contact-radios label {
    margin: 0 10px 0 2px;
    cursor: pointer
}

/*inputs*/
.input-icon>i {
    color: #ffcc29;
    font-size: 25px;
}

.input-icon>.form-control {
    padding-left: 42px;
    color: black !important;
}

.input-icon>.form-control {
    padding-left: 33px;
}

.form-control {
    opacity: 0.8;
    height: 50px;
    padding-left: 40px;
    font-weight: bold;
    font-size: 15px;
}

.form-control {
    font-size: 14px;
    font-weight: normal;
    color: #333;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.input-icon>.form-control {
    padding-left: 42px;
    color: black !important;
}