.pdf-generator {
    display: flex;
    flex-direction: column;
    z-index: 0;
}

.pdf-generator>.container-fluid {

}

.pdf-generator>.container-fluid>.cartao {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pdf-generator>.container-fluid>.cartao>.apoio {
    display: flex;
    justify-content: center;
    background-color: #303030;
    color: #FFFFFF;
    width: 100%;
    height: 100px;
}

.pdf-generator>.container-fluid>.cartao>.apoio>h2 {
    font-size: 80px;
    color: #FFFFFF;
}

.pdf-generator>.container-fluid>.cartao>.pdf-content {
    display: flex;
    align-items: center;
    height: 300px;
}

.pdf-generator>.container-fluid>.cartao>.pdf-content a {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.pdf-generator>.container-fluid>.cartao>.pdf-content h2 {
    font-size: 50px;
}

.pdf-generator>.container-fluid>.cartao>.pdf-content i {
    font-size: 60px;
    color: #000;
}

.pdf-generator>.container-fluid>.cartao>.pdf-content p {
    display: flex;
    align-items: center;
    font-size: 35px;
}

.pdf-generator>.container-fluid>.cartao>.metadados {
    background-color: #ffcc29;
    padding: 10px;
    overflow: hidden;
    height: 2000px;
}

.pdf-generator>.container-fluid>.cartao>.metadados>div {
    border-bottom: 1px solid #000;
    padding: 10px;
    display: flex;
    align-items: center;
}

.pdf-generator>.container-fluid>.cartao>.metadados>div>i,
.pdf-generator>.container-fluid>.cartao>.metadados>div>img {
    color: #0c0c0c;
    font-size: 60px;
    width: 60px;
    height: 60px;
}

.pdf-generator>.container-fluid>.cartao>.metadados>div>i {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
}

.pdf-generator>.container-fluid>.cartao>.metadados>.logo-minisistio>i>img {
    display: flex;
    width: 100%;
}

.pdf-generator>.container-fluid>.cartao>.metadados h4 {
    color: #000000;
    font-weight: 500;
    font-size: 40px;
}

.logo-pix {
    width: 100px !important;
}

@media print {
    .pdf-generator>.container-fluid {
        display: flex;
    }
}