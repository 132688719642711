.list-inline>li {
    display: inline-block;
    padding: 20px 5px 0px 5px;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.btn-xs {
    padding: 7px !important;
    color: #fff !important;
    font-size: 10px;
    margin-right: 5px;
}

.painel-admin .lista {
    padding: 0 0 20px 0;
}

.painel-admin > main #paginacao th {
    text-align: center;
    vertical-align: middle;
}

.painel-admin > main #paginacao td {
    text-align: center;
    vertical-align: middle;
}

.painel-admin > main #paginacao .ver-perfil > a > i:hover {
    transform: scale(1.5);
}

@media (max-width: 768px) {
    .painel-admin #paginacao {
        overflow: scroll;
    }

    .list-inline .fa {
        margin: auto;
    }
}