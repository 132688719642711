#btn-prev {
    background-color: #ffcc29;
    color: #000;
    padding: 10px 40px;
    font-size: 20px;
}

#btn-next {
    background-color: #4f4f4f;
    color: #fff;
    padding: 10px 40px;
    font-size: 20px;
}

#title-caderno {
    box-shadow: 0 6px 4px rgba(228, 228, 228, 0.95);
}

.titulo-cinza {
    background-color: #4f4f4f !important;
    color: #ffffff !important;
    padding: 10px;
}

.border-cinza {
    border: 2px solid #edecec;

}

/* .link-cinza,
.link-cinza:hover {
     filter: gray;
    -webkit-filter: grayscale(1); 
      filter: grayscale(1); 
} */

.btn-comprar {

    padding: 10px 40px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.masonry-layout {
    /*  column-gap: 1em; */
}

.masonry-item {
    break-inside: avoid;
    margin-bottom: 1em;
    background-color: lightgray;
    border: 1px solid lightblue;
    border-radius: 20px;
    /*  padding: 15px; */
    box-sizing: border-box;
    border: 1px solid lime
}

@media (min-width: 576px) {
    .masonry-layout {
        column-count: 2;
    }
}

@media (min-width: 992px) {
    .masonry-layout {
        column-count: 2;
    }
}

@media (min-width: 1200px) {
    .masonry-layout {
        column-count: 2;

    }
}

.my-masonry-grid {
    /* display: -webkit-box; */
    /* Not needed if autoprefixing */
    /*  display: -ms-flexbox; */
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px;
    /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 30px;
    /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column>div {
    /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    }

    100% {
        box-shadow: 0 0 0 8px rgba(255, 215, 0, 1);
    }
}

.pulsating-border {
    animation: pulse 2s infinite;
    /* Define a animação "pulse" com duração de 2 segundos e repetição infinita */
    /* border: 1px solid #ccc;  */
    /* Adicione uma borda inicial */
    /* padding: 20px; */
    /* Adicione algum preenchimento para separar o conteúdo */
}

/* .msg-programada {
    background-color: red;
    height: 11%;
} */


/*layout das colunas----------------------->*/
/* CSS para as colunas */
.secao-anuncios-caderno {
    display: flex;
    /* align-items: flex-start; */
    gap: 10px;
    justify-content: center;

}

.column {
    width: 435px;
}

.column1 {
    width: 50%;
    /* Cada coluna ocupa metade do contêiner */
    max-height: 2356px;
    /* Altura fixa */
    /* overflow-y: auto; */
    /* Ativa rolagem se o conteúdo ultrapassar a altura */
    border: 1px solid black;
    /* Apenas para visualização */
    padding: 10px;
    box-sizing: border-box;
}

.column2 {
    width: 50%;
    /* Cada coluna ocupa metade do contêiner */
    max-height: 2356px;
    /* Altura fixa */
    /* overflow-y: auto; */
    /* Ativa rolagem se o conteúdo ultrapassar a altura */
    border: 1px solid red;
    /* Apenas para visualização */
    padding: 10px;
    box-sizing: border-box;
}

.grid-container {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.grid-container quadrados {
    grid-column: span 1;
    grid-rows: span 4;
}

.grid-container #col1, .grid-container #col2{
    grid-column: span 1;
    grid-rows: span 12;
   /*  border: 1px solid red; */
    max-height: 2352px;
}


.box {
    height: 50px;
    /* Altura das divs que serão adicionadas */
    background-color: lightblue;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .grid-container {
        display: grid;
        align-items: flex-start;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        /* width: 360px; */
    }

    .column {
        padding: 20px;
    }

    .info-title {
        text-align: left;
        /* width: 360px; */
        font-size: 20px;
        padding: 0 20px;
    }

    #btn-prev {
        background-color: #ffcc29;
        color: #000;
        padding: 0px !important;
        font-size: 20px;
        width: 100%;
        height: 50px;
    }
    #btn-next {
        background-color: #ffcc29;
        color: #000;
        padding: 0px !important;
        font-size: 20px;
        width: 100%;
        height: 50px;
    }

    .area-prev {
        padding: 0px;
    }

    .area-next {
        padding: 0px;
    }

    .btn-nav {
        padding: 0px;
    }

    .buttonload {
        top: 50%;
        width: 60%;
        left: 30%;
        transform: translate(-50px, -50px);
    }
    
    .FullWebCard>div {
        padding: 10px !important;
    }

    .FullWebCard>div>.full-title {
        padding: 0px !important;
    }
    
    .caderno .btn {
        padding: 0;
    }

    .caderno .column {
        width: 360px;
    }

    .caderno .column h2 {
        font-size: 18px;
    }

    .FullWebCard .container h1 {
        font-size: 25px;
    }

    .FullWebCard .container .titulo-cinza {
        font-size: 18px;
    }

    .logo {
        display: block;
    }

}