.mosaico-webcard>.header>.container>.container-mosaico {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 200px;
}

.mosaico-webcard>.header>.container>.container-mosaico>a>.logo {
    height: 100px;
    margin-top: 0;
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .mosaico-webcard>.header>.container>.container-mosaico {
        gap: 0px;
    }

    .mosaico-webcard>.header>.container>.container-mosaico>a>.logo {
        height: 70px;
    }
}