.container-login {
    height: 70vh;
}

.cadastro {
    background-color: #ffcc29;
    padding: 20px;

    & button i {
        padding-left: 5px;
    }
}

/* .btn {
    padding: 10px 40px;
    font-size: 20px;
    text-transform: uppercase;
} */

.continuar {
    text-align: right;
}

.vantagens {
    background-color: #4f4f4f;
    text-align: center;
    font-size: 20px;
    padding: 10px;
    text-align: center;
}

.vantagens a {
    color: #fff;
    outline: 0;
    text-decoration: none;
    font-size: 14px;
}


.form-control-login {
    opacity: 0.8;
    height: 30px;
    padding-left: 40px;
    font-weight: bold;
    font-size: 15px;
}

.component-login .bg-cinza {
    background-color: #edecec;
    padding: 20px;
    margin-bottom: 10px;

    & button i {
        padding-left: 5px;
    }
}

@media (max-width: 768px) {
    .container-login {
        height: auto;
    }

    .component-login .bg-cinza {
        margin-bottom: 10px;
    }
}

/* #codTipoPessoa {
    height: 30px;
}
 */


/* .header {
    top: auto;
    bottom: 0;
    left: auto;
    right: auto;
    z-index: 9;
    width: 100%;
    min-height: 60px;
    padding: 0;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 1px 4px rgba(228, 228, 228, 0.95);
    z-index: 9999;
} */