.qrcode-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.qrcode-container>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.qrcode-container button {
    background: #FF730F;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.5, rgb(255, 115, 15)), color-stop(0.84, rgb(255, 132, 0)));
    font-size: 30px;
    font-family: 'myriad_pro_lightbold';
    border-radius: 6px;
    padding: 10px;
    text-align: center;
    display: block;
    color: #fff;
    text-decoration: none;
    margin: 20px 10px 0 auto;
    display: inline;
  }

@media print {
    .no-print {
      display: none;
    }
  }
  