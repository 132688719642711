 .lista .cartao.cartao-simples {
    min-height: 100%;
    width: 100%;
    border: solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: #4F4F4F;
    border-top-color: rgb(79, 79, 79);
    border-right-color: rgb(79, 79, 79);
    border-bottom-color: rgb(79, 79, 79);
    border-left-color: rgb(79, 79, 79);
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
} 

.apoio {
    background-color: #c1c1c1;
    height: 50px;
}

.bgbt {
    background-color: #4f4f4f !important;
    color: #ffffff !important;
    border: none;
    padding: 10px;
}
.btn2 {
    margin-top: 0px;
    padding: 0px 10px;
    font-size: 13px;
}

.MiniWebCardSimples {
    display: flex;
    justify-content: center;
}

.MiniWebCardSimples .webcardsimples {
    /* padding-left:35px  */
}

.MiniWebCardSimples .cartao {
    width: 435px;
    display: flex;
    justify-content: center;
    min-height: 205px;
    margin-bottom: 0px !important;
}

.MiniWebCardSimples .cartao>div {
    overflow: hidden;
    padding: 10px;
    gap: 10px;
}

.cartao {
    background-color: #edecec;
    margin-bottom: 20px;
    cursor: pointer;
    border: 4px solid #4F4F4F;
}

.cartao:hover {
    background-color: #c1c1c1;
}

.MiniWebCardSimples #btn-detalhes {
    background-color: #FAA21E;
    border-radius: 5px;
    color: #000;
    padding: 5px;
    font-size: 15px;
}

#btn-detalhes:hover {
    transform: scale(1.1);
}

/* #dropdown {
    display: flex;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    align-items: center;
    justify-content: center;
} */

#dropdown i {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-toggle::after {
    content: none;
}

#dropdown-redes {
    width: 20px;
}

#dropdown-redes a:hover {
    background-color: #FAA21E;
    /* background-color: rgba(0, 0, 0, 1); */
}

.redes {
    font-size: 1.5rem;
}

#area-icons-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px
}

.WebcardThumb p {
    color: #5f6f7e;
    padding-left: 10px;
}

.atividade-title {
    width: 100%;
    align-self: start;
    break-inside: avoid;
}

.secao-anuncios {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
}